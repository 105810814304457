<template>
  <v-card id="map-static-card" flat
    :to="{name: 'map', params: {parkinfo: parkinfo}}">
    <v-card-title class="justify-center">
      <img :width="imageWidth" :height="imageHeight" :src="mapurl" />
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  components: {
  },
  props: {
    lat: { default: 0 },
    lon: { default: 0 },
    zoom: { default: 9 },
    width: { default: 1024 },
    height: { default: 512 },
    mapstyle: { default: 'outdoors-v11' },
    parkinfo: null
  },
  data() {
    return {
      accessToken: 'pk.eyJ1IjoibjBhdyIsImEiOiJjand6aW5vMHoxZ29qNDluNW1rcnQ1Ymw3In0.Maco-UBPruDo_Gh13oBKPw',
      mapurl: null,
      imageWidth: this.width,
      imageHeight: this.height
    };
  },
  created() {
    window.addEventListener('resize', this.updateMapUrl);
  },
  mounted() {
    this.updateMapUrl();
  },
  methods: {
    updateMapUrl() {
      const lon = this.lon;
      const lat = this.lat;
      const z = this.zoom;
      const mapstyle = this.mapstyle;
      const key = this.accessToken;

      var h = this.height;
      var w = this.width;

      var card = document.querySelector('#map-static-card');

      if (card)
      {
        var ratio = card.clientWidth / this.width;
        if (ratio < 1.0)
        {
          this.imageWidth = parseInt(this.width * ratio);
          this.imageHeight = parseInt(this.height * ratio);
        }
        else
        {
          this.imageWidth = this.width;
          this.imageHeight = this.height;
        }
      }

      //this.mapurl = `https://api.mapbox.com/styles/v1/mapbox/${mapstyle}/static/pin-l-park+f74e4e(${lon},${lat})/${lon},${lat},${z},0,0/${w}x${h}?access_token=${key}`
      
      // devapi.pota.app will not work for this!!!
      this.mapurl = `https://api.pota.app/styles/v1/mapbox/${mapstyle}/static/pin-l-park+f74e4e(${lon},${lat})/${lon},${lat},${z},0,0/${w}x${h}?access_token=${key}`
    }
  },
  watch: {
    lat: function() { this.updateMapUrl(); },
    lon: function() { this.updateMapUrl(); },
    zoom: function() { this.updateMapUrl(); },
    width: function() { this.updateMapUrl(); },
    height: function() { this.updateMapUrl(); },
    mapstyle: function() { this.updateMapUrl(); },
  }
};
</script>

<style scoped>
</style>
