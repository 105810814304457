<template>
  <v-layout row wrap>
    <v-flex md1></v-flex>

    <v-flex xs12 md5>
      <v-form v-model="valid" :disabled="loading || saving">

      <div class="text-h6" v-if="id != null">Editing park {{id}}</div>
      <div class="text-h6" v-else>Creating a new park</div>

      <v-row>
        <v-col>
          <v-select
            v-model="entity"
            :items="entityItems"
            label="DX Entity"
            item-value="name"
            item-text="display">
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="name"
            hint="Name of park excluding type"
            persistent-hint
            :rules="nameRules"
            label="Park Name">
          </v-text-field>
        </v-col>
        <v-col>
          <v-select
            v-model="parktype"
            :items="parktypeItems"
            :hint="`Display: ${name} ${parktype}`"
            persistent-hint
            label="Park Type">
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-autocomplete
            v-model="locations"
            :items="locationItems"
            item-text="display"
            item-value="descriptor"
            chips multiple
            :rules="locationRules"
            label="Locations">
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close small
                @click="data.select"
                @click:close="locations = locations.filter(x => x != data.item.descriptor)">
                {{ data.item.descriptor }}
              </v-chip>
            </template>
            <template v-slot:item="data">
                <v-list-item-content v-text="data.item.display"></v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="latitude"
            :rules="coordinateRules"
            label="Latitude">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="longitude"
            :rules="coordinateRules"
            label="Longitude">
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="website"
            hint="URL of website containing park information"
            persistent-hint
            counter="255"
            label="Website">
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            v-model="accessMethods"
            :items="accessMethodItems"
            chips
            multiple
            label="Access Methods">
          </v-select>
        </v-col>

        <v-col>
          <v-select
            v-model="activationMethods"
            :items="activationMethodItems"
            chips
            multiple
            label="Activation Methods">
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea
            v-model="comments"
            label="Comments"
            auto-grow
            rows="2"
            counter="255"
            hint="Known restrictions or access requirements"
            persistent-hint>
          </v-textarea>
        </v-col>
      </v-row>

      <v-row align="baseline">
        <v-col>
          <v-btn tile color="primary" :disabled="!valid || loading || saving" :loading="saving" @click="save">Save</v-btn>
        </v-col>
        <v-col cols=6>
          <b v-if="error">{{error}}</b>
        </v-col>
        <v-col>
          <v-switch v-model="active" inset label="Active"></v-switch>
        </v-col>
      </v-row>

      <v-row v-if="!active" justify="center">
        <b>WARNING! This park is marked as inactive!</b>
      </v-row>

      </v-form>
    </v-flex>

    <v-flex xs12 md5>
        <map-static-card :lat="latitude" :lon="longitude" :zoom="zoom"></map-static-card>

        <div class="text-h6">Notes:</div>
        <ol>
          <li>If your changes do not show up on the park's info page after 15 minutes, hold <kbd>shift</kbd> and refresh the page.</li>
          <li>It may take several hours before new parks and changes to existing parks are shown correctly on the park list and mapping pages.</li>
        </ol>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios'

import MapStaticCard from '@/components/MapStaticCard'

export default {
  props: {
    id: { required: false }
  },
  data () {
    return {
      entity: '',
      name: '',
      parktype: 'National Park',
      locations: [],
      longitude: 0,
      latitude: 0,
      website: '',
      accessMethods: [],
      activationMethods: [],
      comments: '',
      active: true,

      zoom: 9,

      programs: [],
      entityItems: [],
      parktypeItems: [],

      // are these likely to change? should we get them from an API?
      accessMethodItems: ['Automobile', 'Foot', 'Boat', 'Seaplane/Airtaxi', 'Other'],
      activationMethodItems: ['Pedestrian', 'Automobile', 'Cabin', 'Campground', 'Shelter', 'Other'],

      loading: false,

      error: null,
      valid: false,
      saving: false,

      nameRules: [
        v => !!v || 'Park name is required'
      ],
      locationRules: [
        v => !!(v && v.length) || 'At least one location is required'
      ],
      coordinateRules: [
        v => /^-?\d+(?:\.\d+)?$/.test(v) || 'Example: 90.245 or -20.8',
        v => parseFloat(v) <  180 || 'Expected range -180 to 180',
        v => parseFloat(v) > -180 || 'Expected range -180 to 180'
      ],
    }
  },
  watch: {
    entity: function(valNew, valOld) {
      if (valNew != valOld && valOld != '')
        this.locations = []
    }
  },
  computed: {
    locationItems: function() {
      var locations = [];

      this.entityItems.forEach((item) => {
        if (item.name != this.entity)
          return;

        locations = item.locations;
      })

      return locations;
    }
  },
  components: {
    MapStaticCard
  },
  mounted() {
    this.error = null;
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;

      var requestInfo = null;
      if (this.id)
        requestInfo = axios.get(`https://${process.env.VUE_APP_API_URL}/admin/park/info/${this.id}`);

      var requestPrograms = null;
      if (this.programs.length == 0)
        requestPrograms = axios.get(`https://${process.env.VUE_APP_API_URL}/programs/locations`);

      var requestData = null;
      if (this.parktypeItems.length == 0)
        requestData = axios.get(`https://${process.env.VUE_APP_API_URL}/park/types`);

      var requests = [];
      if (requestInfo) requests.push(requestInfo);
      if (requestPrograms) requests.push(requestPrograms);
      if (requestData) requests.push(requestData);

      axios.all(requests).then(axios.spread((...responses) => {
        var n = 0;

        var info = null;
        if (requestInfo)
        {
          info = responses[n++].data;
          this.__id = info.id;
          this.entity = info.entityName;
          this.name = info.name;
          this.parktype = info.parktypeDesc;
          this.locations = info.locationDesc ? info.locationDesc.split(',').sort() : '';
          this.longitude = info.longitude;
          this.latitude = info.latitude;
          this.website = info.website;
          if (info.accessMethods)
            this.accessMethods = info.accessMethods.split(',');
          else
            this.accessMethods = [];
          if (info.activationMethods)
            this.activationMethods = info.activationMethods.split(',');
          else
            this.activationMethods = [];
          this.comments = info.parkComments;
          this.active = info.active > 0;
        }

        if (requestPrograms)
        {
          var programs = responses[n++].data;

          this.programs = programs;

          var entities = [];

          programs.forEach((program) => {
            program.entities.forEach((entity) => {
              var admin = this.$store.getters.isParkAdmin(entity.prefix);
              var item = { name: entity.name, prefix: program.prefix, display: `${entity.name} (${program.prefix})`, locations: [] };

              entity.locations.forEach(location => {
                if (!admin && !this.$store.getters.isParkAdmin(location.descriptor))
                  return;

                location.display = `(${location.descriptor}) ${location.name}`;
                location.entity = entity.name;
                item.locations.push(location);
              });

              if (item.locations.length > 0)
                entities.push(item);
            });
          });

          entities.sort((a, b) => a.name.localeCompare(b.name))

          this.entityItems = entities;
        }

        if (requestData)
          this.parktypeItems = responses[n++].data

        if (info)
            this.entity = info.entityName;

        this.loading = false;
      }));
    },

    save() {
      if (!this.valid)
        return;

      this.saving = true;

      var prefix = null;
      this.entityItems.forEach((entity) => {
        if (entity.name == this.entity)
          prefix = entity.prefix;
      });

      if (!this.$store.getters.isParkAdmin(prefix))
      {
        var access = false;

        for (var i = 0; i < this.locations.length; i++)
        {
          if (this.$store.getters.isParkAdmin(this.locations[i]))
          {
            access = true;
            break;
          }
        }

        if (!access) {
          this.saving = false;
          this.error = "You are not authorized to modify parks for entity or location(s)";
          return;
        }
      }

      if (!this.id && !this.$store.getters.isParkCreator)
      {
        this.saving = false;
        this.error = "You do not have authorization to create a new park";
        return;
      }

      var data = {
        prefix: prefix,
        reference: this.id,
        entity: this.entity,
        name: this.name,
        parktype: this.parktype,
        locations: this.locations.sort(),
        latitude: this.latitude,
        longitude: this.longitude,
        website: this.website,
        accessMethods: this.accessMethods,
        activationMethods: this.activationMethods,
        comments: this.comments,
        active: this.active
      };

      var url = `https://${process.env.VUE_APP_API_URL}/admin/park/create`
      if (this.id != null)
        url = `https://${process.env.VUE_APP_API_URL}/admin/park/edit`

      axios
          .post(url, data, this.$store.getters.authTokenHeader)
          .then((response) => {
            var reference = response.data;
            this.$router.push({ name: 'park', params: { id: reference } })
          })
          .catch((error) => {
            this.error = error.response.data;
            this.saving = false;
          });
    }
  },
}
</script>
